import { formatTokenCurrency } from '@/utils/formatAmount';
import { GetAssetsResponse } from '@/apiClient/types/asset';
import { CurrencyCode } from '../types/currency';

interface AssetProps {
  currencySymbol: CurrencyCode;
  // Numeric string
  chainId: string;
  // Numeric string
  unlockedBalance: string;
  // Numeric string
  lockedBalance: string;
}

export default class Asset implements AssetProps {
  currencySymbol: CurrencyCode;
  chainId: string;
  unlockedBalance: string;
  lockedBalance: string;

  constructor(props: AssetProps) {
    this.currencySymbol = props.currencySymbol;
    this.chainId = props.chainId;
    this.unlockedBalance = props.unlockedBalance;
    this.lockedBalance = props.lockedBalance;
  }

  get lockedBalanceFormatted() {
    return formatTokenCurrency({
      value: Number(this.lockedBalance),
      currency: this.currencySymbol,
      isMinorUnits: true
    });
  }

  get unlockedBalanceFormatted() {
    return formatTokenCurrency({
      value: Number(this.unlockedBalance),
      currency: this.currencySymbol,
      isMinorUnits: true
    });
  }

  // Used to create Asset from /api/assets api response
  static fromAssets(data: GetAssetsResponse) {
    return new Asset({
      chainId: data.chainId,
      currencySymbol: data.currencySymbol,
      lockedBalance: data.availableBalance,
      unlockedBalance: data.balance
    });
  }
}
