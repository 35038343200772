import { formatTokenCurrency, convertFiatToToken } from '@/utils/formatAmount';
import { ActivityResponse, TransactionResponse } from '@/apiClient/types/transaction';

interface TransactionProps {
  id: string;
  amount: number;
  currency: string;
  description: string;
  processedDate?: string;
  transactionDate: string;
  type: 'deposit' | 'withdrawal' | 'spend';
}

export default class Transaction implements TransactionProps {
  id: string;
  amount: number;
  currency: string;
  description: string;
  processedDate?: string;
  transactionDate: string;
  type: 'deposit' | 'withdrawal' | 'spend';

  constructor(props: TransactionProps) {
    this.id = props.id;
    this.type = props.type;
    this.amount = props.amount;
    this.currency = props.currency;
    this.description = props.description;
    this.processedDate = props.processedDate;
    this.transactionDate = props.transactionDate;
  }

  static fromTransaction(props: TransactionResponse) {
    return new Transaction({
      id: props.id,
      type: 'spend',
      amount: convertFiatToToken({
        amount: props.amount,
        fiatCurrency: props.currency,
        tokenCurrency: props.lockedFundCurrency
      }),
      description: props.description,
      currency: props.lockedFundCurrency,
      processedDate: props.processedDate,
      transactionDate: props.transactionDate
    });
  }

  static fromActivity(props: ActivityResponse) {
    return new Transaction({
      id: props.eventId,
      amount: props.amount,
      currency: props.lockedFundCurrency,
      processedDate: props.transactionDate,
      transactionDate: props.transactionDate,
      type: /deposit/i.test(props.eventType) ? 'deposit' : 'withdrawal',
      description: /deposit/i.test(props.eventType) ? 'deposit' : 'withdrawal'
    });
  }

  get formattedAmount() {
    const prefix = this.type === 'deposit' ? '+' : '-';
    const fmtAmount = formatTokenCurrency({
      value: this.amount,
      currency: this.currency,
      isMinorUnits: true
    });
    return [prefix, fmtAmount].join('');
  }
}
