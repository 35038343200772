import router from '@/router';
import { config } from '@/config';
import Transaction from '@/models/Transaction';
import axios, { AxiosError, AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { SessionDetails, useSessionsStore } from '@/store/sessions.store';
import { ActivityResponse, PageInfo, TransactionResponse } from '@/apiClient/types/transaction';
import { GetAssetsResponse } from '@/apiClient/types/asset';
import Asset from '@/models/Asset';
import {
  CancelCardResponse,
  CardCreateResponseItem,
  CardResponseItem,
  CardSecureDetails,
  CreateCardBody,
  ListCardsQueryParams,
  ListCardsResponse,
  OrderCardParams,
  TokenDTO
} from '@/apiClient/types/card';
import { Card } from '@/models/Card';
import {
  FetchConvertedCurrencyParams,
  FetchConvertedCurrencyResponse,
  ListCurrenciesResponse
} from '@/apiClient/types/currency';
import {
  ListSpendingPrerequisitesBody,
  ListSpendingPrerequisitesResponse
} from '@/apiClient/types/prerequisite';
import jwtDecode from 'jwt-decode';
import {
  CreateFundingSourceProps,
  FundingSourceResponseItem,
  ListFundingSourceResponse,
  WithdrawFundingSourceProps
} from './types/fundingSource';
import * as Sentry from '@sentry/vue';
import { FundingSource } from '@/models/FundingSource';
import { WithdrawalResponseItem } from './types/fundingSource';
import { ListFundingSourceInteractionsResponse } from './types/fundingSourceInteraction';
import { FundingSourceInteraction } from '@/models/FundingSourceInteraction';
import { getUnixTime } from 'date-fns';
import { isTokenExpiredError } from './errorChecks';

class ImmersveApiClient {
  private readonly baseUrl = config.TEST_MODE_ENABLED
    ? config.TEST_MODE_API_BASE_URL
    : config.API_BASE_URL;

  axiosInstance: AxiosInstance;

  public tokenPromise: Promise<void> | null = null;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: this.baseUrl
    });
    axiosRetry(this.axiosInstance, {
      retries: 2,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: (err) => {
        return axiosRetry.isNetworkError(err) || axiosRetry.isRetryableError(err);
      }
    });

    this.axiosInstance.interceptors.request.use(async (req) => {
      const controller = new AbortController();
      const sessions = useSessionsStore();
      if (!sessions.currentSession) {
        controller.abort();
        router.push('/login');
        return { ...req, signal: controller.signal };
      }
      await this.ensureValidTokens(sessions.currentSession);
      const token = useSessionsStore().currentSession?.token;
      req.headers.Authorization = `Bearer ${token}`;
      return { ...req, signal: controller.signal };
    });

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (err) => {
        if (err.code === AxiosError.ERR_CANCELED) {
          return Promise.reject(err);
        }
        const originalRequest = err.config;

        if (isTokenExpiredError(err) && !originalRequest._retry) {
          originalRequest._retry = true;

          if (this.tokenPromise) {
            await this.tokenPromise;
          }

          const newToken = useSessionsStore().currentSession?.token;
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return this.axiosInstance(originalRequest);
        }

        Sentry.captureException(err);
        throw err;
      }
    );
  }

  async ensureValidTokens(session: SessionDetails) {
    const { accessTokenExp, refreshToken } = session;
    const decodedRefreshToken = jwtDecode(refreshToken) as { [key: string]: string };
    const refreshTokenExp = Number(decodedRefreshToken.exp);
    const nowUnix = getUnixTime(new Date());

    if (refreshToken && nowUnix > refreshTokenExp) {
      const sessions = useSessionsStore();
      sessions.clear();
      router.push('/login');
      return;
    }

    if (refreshToken && nowUnix > accessTokenExp - 60) {
      if (!this.tokenPromise) {
        this.tokenPromise = this.refreshToken(session);
        await this.tokenPromise.finally(() => {
          this.tokenPromise = null;
        });
      }
    } else {
      return;
    }
  }

  private async refreshToken(session: SessionDetails) {
    const clientApplicationId = config.TEST_MODE_ENABLED
      ? config.API_CLIENT_APPLICATION_ID_TEST
      : config.API_CLIENT_APPLICATION_ID_LIVE;

    try {
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
        await this.exchangeTokensRequest({
          refreshToken: session.refreshToken,
          clientApplicationId
        });
      const decodedToken = jwtDecode(newAccessToken) as { [key: string]: string };
      const sessions = useSessionsStore();
      sessions.setSessionDetails({
        walletAddress: sessions.currentAccount!,
        details: {
          ...session,
          token: newAccessToken,
          refreshToken: newRefreshToken,
          accessTokenExp: Number(decodedToken.exp)
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to refresh token:', error);
      const sessions = useSessionsStore();
      sessions.clear();
      router.push('/login');
    }
  }

  /**
   *
   * @deprecated use {@link listFundingSourceInteractions} instead
   */
  async getActivities(assetId: string, { limit }: { limit: number } = { limit: 10 }) {
    const { data } = await this.axiosInstance.get<{
      items: ActivityResponse[];
      pageInfo: PageInfo;
    }>(`/api/assets/${assetId}/activities?limit=${limit}`);

    return {
      items: data.items.map(Transaction.fromActivity),
      pageInfo: data.pageInfo
    };
  }

  /**
   *
   * @deprecated use {@link listFundingSourceInteractions} instead
   */
  async listTransactions(
    accountId: string | undefined,
    { limit, cursor, cardId }: { limit: number; cursor?: string; cardId?: string } = { limit: 10 }
  ) {
    const cursorQuery = cursor ? `&cursor=${cursor}` : '';
    const { data } = await this.axiosInstance.get<{
      items: TransactionResponse[];
      pageInfo: PageInfo;
    }>(`/api/accounts/${accountId}/transactions?limit=${limit}${cursorQuery}`);

    const filteredItems = cardId ? data.items.filter((item) => item.cardId === cardId) : data.items;
    return {
      items: filteredItems.map(Transaction.fromTransaction),
      pageInfo: data.pageInfo
    };
  }

  async getAssets() {
    const { data } = await this.axiosInstance<GetAssetsResponse>({
      method: 'GET',
      url: '/api/assets'
    });

    return Asset.fromAssets(data);
  }

  async listCards(accountId: string, params: ListCardsQueryParams) {
    const { data } = await this.axiosInstance<ListCardsResponse>({
      method: 'GET',
      url: `/api/accounts/${accountId}/cards`,
      params
    });

    return { ...data, items: data.items.map(Card.fromResponse) };
  }

  async listCurrencies() {
    const { data } = await this.axiosInstance<ListCurrenciesResponse>({
      method: 'GET',
      url: '/api/currencies'
    });

    return data;
  }

  async fetchConvertedCurrency(params: FetchConvertedCurrencyParams) {
    const { data } = await this.axiosInstance<FetchConvertedCurrencyResponse>({
      method: 'GET',
      url: '/api/currency/convert',
      params
    });
    return data;
  }

  async listSpendingPrerequisites(props: ListSpendingPrerequisitesBody) {
    const {
      cardProgramId,
      fundingSourceId,
      spendableAmount,
      spendableCurrency,
      kycType,
      kycRedirectUrl,
      kycRegion
    } = props;
    const { data } = await this.axiosInstance<ListSpendingPrerequisitesResponse>({
      method: 'POST',
      url: '/api/spending-prerequisites',
      data: {
        cardProgramId,
        fundingSourceId,
        spendableAmount,
        spendableCurrency,
        kycType,
        kycRedirectUrl,
        kycRegion
      }
    });
    return data;
  }

  async createCard(body: CreateCardBody, params?: OrderCardParams) {
    const { data } = await this.axiosInstance<CardCreateResponseItem>({
      method: 'POST',
      url: '/api/cards',
      data: { ...body },
      params
    });
    return data;
  }

  async getCard(cardId: string) {
    const { data } = await this.axiosInstance<CardResponseItem>({
      method: 'GET',
      url: `/api/cards/${cardId}`
    });
    return Card.fromResponse(data);
  }

  async createCardPanToken(cardId: string) {
    const { data } = await this.axiosInstance<TokenDTO>({
      method: 'POST',
      url: `/api/cards/${cardId}/pan-token`
    });
    return data;
  }

  async getCardSecureDetails(callbackUrl: string) {
    const { data } = await this.axiosInstance<CardSecureDetails>({
      method: 'GET',
      url: callbackUrl
    });
    return data;
  }

  async cancelCard(cardId: string) {
    const { data } = await this.axiosInstance<CancelCardResponse>({
      method: 'POST',
      url: `/api/cards/${cardId}/cancel-async`
    });
    return data;
  }

  async listFundingSources(accountId: string) {
    const { data } = await this.axiosInstance<ListFundingSourceResponse>({
      method: 'GET',
      url: `/api/accounts/${accountId}/funding-sources`
    });

    return data.items.map(FundingSource.fromApiResponse);
  }

  async createFundingSource(props: CreateFundingSourceProps) {
    const fundingTypeOrChannel = props.fundingChannelId
      ? { fundingChannelId: props.fundingChannelId }
      : { fundingSourceType: props.fundingSourceType };

    const { data } = await this.axiosInstance<FundingSourceResponseItem>({
      method: 'POST',
      url: `/api/funding-sources`,
      data: {
        ...fundingTypeOrChannel,
        accountId: props.accountId,
        fundingAddress: props.fundingAddress
      }
    });

    return data;
  }

  async createWithdrawIntent(props: WithdrawFundingSourceProps) {
    const { data } = await this.axiosInstance<WithdrawalResponseItem>({
      method: 'POST',
      url: `/api/funding-sources/${props.fundingSourceId}/withdraw`,
      data: {
        amount: props.amount
      }
    });

    return data;
  }

  async setCardPin(props: { cardId: string; newPin: string }): Promise<void> {
    const { cardId, newPin } = props;
    await this.axiosInstance({
      method: 'POST',
      url: `/api/cards/${cardId}/set-pin`,
      data: { newPin }
    });
  }

  async listFundingSourceInteractions(props: {
    fundingSourceId: string;
    limit?: number;
    cursor?: string;
  }) {
    const { fundingSourceId, limit, cursor } = props;
    const { data } = await this.axiosInstance<ListFundingSourceInteractionsResponse>({
      method: 'GET',
      url: `/api/funding-sources/${fundingSourceId}/interactions`,
      params: { limit, cursor }
    });
    return {
      items: data.items.map(FundingSourceInteraction.fromApiResponse),
      pageInfo: data.pageInfo
    };
  }

  async exchangeTokensRequest(props: { refreshToken: string; clientApplicationId: string }) {
    const { refreshToken, clientApplicationId } = props;

    // Uses custom axios instance to avoid interceptor trap
    const axiosInstance = axios.create({
      baseURL: this.baseUrl
    });

    const { data } = await axiosInstance<{ accessToken: string; refreshToken: string }>({
      method: 'POST',
      url: `/auth/token`,
      data: { refreshToken, clientApplicationId }
    });
    return data;
  }

  async logout() {
    const { data } = await this.axiosInstance({
      method: 'POST',
      url: `/auth/logout`
    });
    return data;
  }
}

export default new ImmersveApiClient();
