<script setup lang="ts">
import { subMinutes, toDate } from 'date-fns';
import { useQuery } from '@tanstack/vue-query';
import CardCarousel from '@/components/CardCarousel.vue';
import { storeToRefs } from 'pinia';
import apiClient from '@/apiClient';
import { useSessionsStore } from '@/store/sessions.store';
import { CardStatus } from '@/types/card';
import ArrowRight from '@/components/icons/ArrowRight.vue';
import LoadingSpinner from '@/components/icons/LoadingSpinner.vue';

const sessions = useSessionsStore();
const { currentSession } = storeToRefs(sessions);

const { data: cards, isFetching } = useQuery({
  queryKey: ['dashboard-list-cards', currentSession],
  // This page is authorized and sessions.currentAccount
  // must be defined in order for this page to render
  queryFn: () => {
    const expiresAfter = toDate(subMinutes(new Date(), 2)).toISOString();
    if (!currentSession.value?.user.accountId) {
      return {
        items: []
      };
    }
    return apiClient.listCards(currentSession.value?.user.accountId!, {
      status: CardStatus.ACTIVE,
      excludeExpired: false,
      expiresAfter,
      useMinorUnits: true
    });
  },
  initialData: {
    items: []
  },
  keepPreviousData: true
});
</script>

<template>
  <div>
    <div class="mb-6 flex items-center flex-wrap">
      <p class="mr-4 text-2xl font-semibold basis-full md:basis-auto">
        {{ cards.items.length }} active cards
      </p>

      <RouterLink
        to="/cards"
        class="flex h-fit items-center"
        data-testid="cards-widget-manage-cards-link"
      >
        <p class="mr-2 text-sm font-semibold">Manage cards</p>
        <ArrowRight class="h-4" />
      </RouterLink>
      <LoadingSpinner v-if="isFetching" class="ml-4" />
    </div>

    <CardCarousel :cards="cards.items" />
  </div>
</template>
