<script setup lang="ts">
import { format } from 'date-fns';
import Transaction from '@/models/Transaction';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

const props = defineProps<{
  transactions: Transaction[];
}>();

const breakpoints = useBreakpoints(breakpointsTailwind);
const isLgOrUp = breakpoints.greaterOrEqual('lg');
const { href: polygonLogo } = new URL('@/assets/icons/polygon.svg', import.meta.url);

const shortenedDescription = (description: String) => {
  if (description.length < 18) {
    return description;
  }
  return description.slice(0, 16) + '...';
};
</script>
<template>
  <div v-if="!transactions.length" class="pt-8 lg:pl-4">There are no transactions.</div>
  <table v-else>
    <thead class="hidden lg:table-header-group border-b border-gray-800">
      <tr class="text-left text-sm">
        <th class="p-4 font-normal">Transaction Date</th>
        <th class="font-normal">Description</th>
        <th class="font-normal">Amount</th>
        <th class="font-normal">Chain</th>
      </tr>
    </thead>
    <tbody v-if="isLgOrUp">
      <tr
        v-for="transaction in props.transactions"
        :key="transaction.id"
        class="border-b border-gray-800 text-sm"
      >
        <td class="p-4">
          {{ format(new Date(transaction.transactionDate), 'MMM dd, yyyy') }}
        </td>
        <td>
          {{ transaction.description }}
        </td>
        <td>
          {{ transaction.formattedAmount }}
        </td>
        <td>
          <div class="flex flex-row items-center">
            <img class="h-8 min-w-fit" :src="polygonLogo" alt="Polygon Logo" />
            <p class="px-4">Polygon</p>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="transaction in props.transactions"
        :key="transaction.id"
        class="border-b border-gray-800"
      >
        <td class="p-4">
          <div class="text-sm text-stone-400">
            {{ format(new Date(transaction.transactionDate), 'MMM dd, yyyy') }}
          </div>
          <div>
            {{ shortenedDescription(transaction.description) }}
          </div>
        </td>
        <td class="text-right text-base">
          {{ transaction.formattedAmount }}
        </td>
        <td>
          <img class="h-4 min-w-fit ps-2" :src="polygonLogo" alt="Polygon Logo" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
